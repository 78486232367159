"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqSubtopicQuestionAnswerToJSONTyped = exports.FaqSubtopicQuestionAnswerToJSON = exports.FaqSubtopicQuestionAnswerFromJSONTyped = exports.FaqSubtopicQuestionAnswerFromJSON = void 0;
function FaqSubtopicQuestionAnswerFromJSON(json) {
    return FaqSubtopicQuestionAnswerFromJSONTyped(json, false);
}
exports.FaqSubtopicQuestionAnswerFromJSON = FaqSubtopicQuestionAnswerFromJSON;
function FaqSubtopicQuestionAnswerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'light': json['light'],
        'active': json['active'],
        'sandokan': json['sandokan'],
        'rtlhu': json['rtlhu'],
        'linear': json['linear'],
        'common': json['common'],
    };
}
exports.FaqSubtopicQuestionAnswerFromJSONTyped = FaqSubtopicQuestionAnswerFromJSONTyped;
function FaqSubtopicQuestionAnswerToJSON(value) {
    return FaqSubtopicQuestionAnswerToJSONTyped(value, false);
}
exports.FaqSubtopicQuestionAnswerToJSON = FaqSubtopicQuestionAnswerToJSON;
function FaqSubtopicQuestionAnswerToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'light': value.light,
        'active': value.active,
        'sandokan': value.sandokan,
        'rtlhu': value.rtlhu,
        'linear': value.linear,
        'common': value.common,
    };
}
exports.FaqSubtopicQuestionAnswerToJSONTyped = FaqSubtopicQuestionAnswerToJSONTyped;
