"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostCastingFormMultipartFormDataToJSONTyped = exports.PostCastingFormMultipartFormDataToJSON = exports.PostCastingFormMultipartFormDataFromJSONTyped = exports.PostCastingFormMultipartFormDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function PostCastingFormMultipartFormDataFromJSON(json) {
    return PostCastingFormMultipartFormDataFromJSONTyped(json, false);
}
exports.PostCastingFormMultipartFormDataFromJSON = PostCastingFormMultipartFormDataFromJSON;
function PostCastingFormMultipartFormDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'age': !(0, runtime_1.exists)(json, 'age') ? undefined : json['age'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'family': !(0, runtime_1.exists)(json, 'family') ? undefined : json['family'],
        'children': !(0, runtime_1.exists)(json, 'children') ? undefined : json['children'],
        'profession': !(0, runtime_1.exists)(json, 'profession') ? undefined : json['profession'],
        'school': !(0, runtime_1.exists)(json, 'school') ? undefined : json['school'],
        'gdprContribution': !(0, runtime_1.exists)(json, 'gdpr_contribution') ? undefined : json['gdpr_contribution'],
        'additionalProperties': !(0, runtime_1.exists)(json, 'additionalProperties') ? undefined : json['additionalProperties'],
    };
}
exports.PostCastingFormMultipartFormDataFromJSONTyped = PostCastingFormMultipartFormDataFromJSONTyped;
function PostCastingFormMultipartFormDataToJSON(value) {
    return PostCastingFormMultipartFormDataToJSONTyped(value, false);
}
exports.PostCastingFormMultipartFormDataToJSON = PostCastingFormMultipartFormDataToJSON;
function PostCastingFormMultipartFormDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'age': value.age,
        'email': value.email,
        'phone': value.phone,
        'family': value.family,
        'children': value.children,
        'profession': value.profession,
        'school': value.school,
        'gdpr_contribution': value.gdprContribution,
        'additionalProperties': value.additionalProperties,
    };
}
exports.PostCastingFormMultipartFormDataToJSONTyped = PostCastingFormMultipartFormDataToJSONTyped;
