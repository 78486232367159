"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlternativeImageUrlsToJSONTyped = exports.AlternativeImageUrlsToJSON = exports.AlternativeImageUrlsFromJSONTyped = exports.AlternativeImageUrlsFromJSON = void 0;
const runtime_1 = require("../runtime");
function AlternativeImageUrlsFromJSON(json) {
    return AlternativeImageUrlsFromJSONTyped(json, false);
}
exports.AlternativeImageUrlsFromJSON = AlternativeImageUrlsFromJSON;
function AlternativeImageUrlsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'alternativeImageUrl': !(0, runtime_1.exists)(json, 'alternativeImageUrl') ? undefined : json['alternativeImageUrl'],
        'alternativeImageUrl169': !(0, runtime_1.exists)(json, 'alternativeImageUrl_16-9') ? undefined : json['alternativeImageUrl_16-9'],
        'alternativeImageUrl166': !(0, runtime_1.exists)(json, 'alternativeImageUrl_16-6') ? undefined : json['alternativeImageUrl_16-6'],
        'alternativeImageUrl72': !(0, runtime_1.exists)(json, 'alternativeImageUrl_7-2') ? undefined : json['alternativeImageUrl_7-2'],
        'alternativeImageUrl34': !(0, runtime_1.exists)(json, 'alternativeImageUrl_3-4') ? undefined : json['alternativeImageUrl_3-4'],
        'alternativeImageUrl43': !(0, runtime_1.exists)(json, 'alternativeImageUrl_4-3') ? undefined : json['alternativeImageUrl_4-3'],
        'alternativeImageUrl11': !(0, runtime_1.exists)(json, 'alternativeImageUrl_1-1') ? undefined : json['alternativeImageUrl_1-1'],
    };
}
exports.AlternativeImageUrlsFromJSONTyped = AlternativeImageUrlsFromJSONTyped;
function AlternativeImageUrlsToJSON(value) {
    return AlternativeImageUrlsToJSONTyped(value, false);
}
exports.AlternativeImageUrlsToJSON = AlternativeImageUrlsToJSON;
function AlternativeImageUrlsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'alternativeImageUrl': value.alternativeImageUrl,
        'alternativeImageUrl_16-9': value.alternativeImageUrl169,
        'alternativeImageUrl_16-6': value.alternativeImageUrl166,
        'alternativeImageUrl_7-2': value.alternativeImageUrl72,
        'alternativeImageUrl_3-4': value.alternativeImageUrl34,
        'alternativeImageUrl_4-3': value.alternativeImageUrl43,
        'alternativeImageUrl_1-1': value.alternativeImageUrl11,
    };
}
exports.AlternativeImageUrlsToJSONTyped = AlternativeImageUrlsToJSONTyped;
