"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorListDataPayloadToJSONTyped = exports.AuthorListDataPayloadToJSON = exports.AuthorListDataPayloadFromJSONTyped = exports.AuthorListDataPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthorListDataPayloadFromJSON(json) {
    return AuthorListDataPayloadFromJSONTyped(json, false);
}
exports.AuthorListDataPayloadFromJSON = AuthorListDataPayloadFromJSON;
function AuthorListDataPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'excludedAuthorIds': !(0, runtime_1.exists)(json, 'excludedAuthorIds') ? undefined : json['excludedAuthorIds'],
        'excludedAuthors': !(0, runtime_1.exists)(json, 'excludedAuthors') ? undefined : json['excludedAuthors'],
        'order': !(0, runtime_1.exists)(json, 'order') ? undefined : (json['order'].map(_1.ContentListQueryOrderFromJSON)),
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'offset': !(0, runtime_1.exists)(json, 'offset') ? undefined : json['offset'],
    };
}
exports.AuthorListDataPayloadFromJSONTyped = AuthorListDataPayloadFromJSONTyped;
function AuthorListDataPayloadToJSON(value) {
    return AuthorListDataPayloadToJSONTyped(value, false);
}
exports.AuthorListDataPayloadToJSON = AuthorListDataPayloadToJSON;
function AuthorListDataPayloadToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'excludedAuthorIds': value.excludedAuthorIds,
        'excludedAuthors': value.excludedAuthors,
        'order': value.order === undefined ? undefined : (value.order.map(_1.ContentListQueryOrderToJSON)),
        'limit': value.limit,
        'offset': value.offset,
    };
}
exports.AuthorListDataPayloadToJSONTyped = AuthorListDataPayloadToJSONTyped;
