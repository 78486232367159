"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqTopicToJSONTyped = exports.FaqTopicToJSON = exports.FaqTopicFromJSONTyped = exports.FaqTopicFromJSON = void 0;
const _1 = require("./");
function FaqTopicFromJSON(json) {
    return FaqTopicFromJSONTyped(json, false);
}
exports.FaqTopicFromJSON = FaqTopicFromJSON;
function FaqTopicFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': json['label'],
        'tag': json['tag'],
        'icon': json['icon'],
        'subTopics': (json['subTopics'].map(_1.FaqSubtopicFromJSON)),
    };
}
exports.FaqTopicFromJSONTyped = FaqTopicFromJSONTyped;
function FaqTopicToJSON(value) {
    return FaqTopicToJSONTyped(value, false);
}
exports.FaqTopicToJSON = FaqTopicToJSON;
function FaqTopicToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'tag': value.tag,
        'icon': value.icon,
        'subTopics': (value.subTopics.map(_1.FaqSubtopicToJSON)),
    };
}
exports.FaqTopicToJSONTyped = FaqTopicToJSONTyped;
