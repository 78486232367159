"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentListDataPayloadToJSONTyped = exports.ContentListDataPayloadToJSON = exports.ContentListDataPayloadFromJSONTyped = exports.ContentListDataPayloadFromJSON = exports.ContentListDataPayloadRecursiveEnum = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
/**
* @export
* @enum {string}
*/
var ContentListDataPayloadRecursiveEnum;
(function (ContentListDataPayloadRecursiveEnum) {
    ContentListDataPayloadRecursiveEnum[ContentListDataPayloadRecursiveEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ContentListDataPayloadRecursiveEnum[ContentListDataPayloadRecursiveEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ContentListDataPayloadRecursiveEnum = exports.ContentListDataPayloadRecursiveEnum || (exports.ContentListDataPayloadRecursiveEnum = {}));
function ContentListDataPayloadFromJSON(json) {
    return ContentListDataPayloadFromJSONTyped(json, false);
}
exports.ContentListDataPayloadFromJSON = ContentListDataPayloadFromJSON;
function ContentListDataPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'offset': !(0, runtime_1.exists)(json, 'offset') ? undefined : json['offset'],
        'sortBy': !(0, runtime_1.exists)(json, 'sortBy') ? undefined : (0, _1.PayLoadSortByFromJSON)(json['sortBy']),
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : (0, _1.PayLoadSortOrderFromJSON)(json['sortOrder']),
        'contentTypes': !(0, runtime_1.exists)(json, 'contentTypes') ? undefined : (json['contentTypes'].map(_1.PayLoadContentTypeFromJSON)),
        'adultContent': !(0, runtime_1.exists)(json, 'adultContent') ? undefined : json['adultContent'],
        'folderIds': !(0, runtime_1.exists)(json, 'folderIds') ? undefined : json['folderIds'],
        'contentIds': !(0, runtime_1.exists)(json, 'contentIds') ? undefined : json['contentIds'],
        'recursive': !(0, runtime_1.exists)(json, 'recursive') ? undefined : json['recursive'],
        'contentId': !(0, runtime_1.exists)(json, 'contentId') ? undefined : json['contentId'],
        'tag': !(0, runtime_1.exists)(json, 'tag') ? undefined : json['tag'],
        'dateFrom': !(0, runtime_1.exists)(json, 'dateFrom') ? undefined : (json['dateFrom'] === null ? null : new Date(json['dateFrom'])),
        'dateFromRaw': !(0, runtime_1.exists)(json, 'dateFrom') ? undefined : (json['dateFrom'] === null ? null : json['dateFrom']),
        'dateTo': !(0, runtime_1.exists)(json, 'dateTo') ? undefined : (json['dateTo'] === null ? null : new Date(json['dateTo'])),
        'dateToRaw': !(0, runtime_1.exists)(json, 'dateTo') ? undefined : (json['dateTo'] === null ? null : json['dateTo']),
        'priority': !(0, runtime_1.exists)(json, 'priority') ? undefined : json['priority'],
        'channel': !(0, runtime_1.exists)(json, 'channel') ? undefined : json['channel'],
        'cast': !(0, runtime_1.exists)(json, 'cast') ? undefined : json['cast'],
        'season': !(0, runtime_1.exists)(json, 'season') ? undefined : json['season'],
        'filterTags': !(0, runtime_1.exists)(json, 'filterTags') ? undefined : json['filterTags'],
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
        'authorSlug': !(0, runtime_1.exists)(json, 'authorSlug') ? undefined : json['authorSlug'],
    };
}
exports.ContentListDataPayloadFromJSONTyped = ContentListDataPayloadFromJSONTyped;
function ContentListDataPayloadToJSON(value) {
    return ContentListDataPayloadToJSONTyped(value, false);
}
exports.ContentListDataPayloadToJSON = ContentListDataPayloadToJSON;
function ContentListDataPayloadToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'limit': value.limit,
        'offset': value.offset,
        'sortBy': (0, _1.PayLoadSortByToJSON)(value.sortBy),
        'sortOrder': (0, _1.PayLoadSortOrderToJSON)(value.sortOrder),
        'contentTypes': value.contentTypes === undefined ? undefined : (value.contentTypes.map(_1.PayLoadContentTypeToJSON)),
        'adultContent': value.adultContent,
        'folderIds': value.folderIds,
        'contentIds': value.contentIds,
        'recursive': value.recursive,
        'contentId': value.contentId,
        'tag': value.tag,
        'dateFrom': value.dateFrom === undefined ? undefined : (value.dateFrom === null ? null : value.dateFrom.toISOString().substr(0, 10)),
        'dateTo': value.dateTo === undefined ? undefined : (value.dateTo === null ? null : value.dateTo.toISOString().substr(0, 10)),
        'priority': value.priority,
        'channel': value.channel,
        'cast': value.cast,
        'season': value.season,
        'filterTags': value.filterTags,
        'totalCount': value.totalCount,
        'authorSlug': value.authorSlug,
    };
}
exports.ContentListDataPayloadToJSONTyped = ContentListDataPayloadToJSONTyped;
