"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentDataToJSONTyped = exports.ContentDataToJSON = exports.ContentDataFromJSONTyped = exports.ContentDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ContentDataFromJSON(json) {
    return ContentDataFromJSONTyped(json, false);
}
exports.ContentDataFromJSON = ContentDataFromJSON;
function ContentDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'ageRating': !(0, runtime_1.exists)(json, 'ageRating') ? undefined : json['ageRating'],
        'authors': !(0, runtime_1.exists)(json, 'authors') ? undefined : (json['authors'].map(_1.AuthorFromJSON)),
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
        'contentType': !(0, runtime_1.exists)(json, 'contentType') ? undefined : (0, _1.ContentTypeFromJSON)(json['contentType']),
        'createdTs': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (new Date(json['createdTs'])),
        'createdTsRaw': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (json['createdTs']),
        'id': json['id'],
        'lead': !(0, runtime_1.exists)(json, 'lead') ? undefined : json['lead'],
        'leadImageUrls': !(0, runtime_1.exists)(json, 'leadImageUrls') ? undefined : (0, _1.LeadImageUrlsFromJSON)(json['leadImageUrls']),
        'modifiedTs': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (new Date(json['modifiedTs'])),
        'modifiedTsRaw': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (json['modifiedTs']),
        'nsfwFlag': !(0, runtime_1.exists)(json, 'nsfwFlag') ? undefined : json['nsfwFlag'],
        'outerUrl': !(0, runtime_1.exists)(json, 'outerUrl') ? undefined : json['outerUrl'],
        'params': !(0, runtime_1.exists)(json, 'params') ? undefined : (0, _1.ContentParamsFromJSON)(json['params']),
        'seoKeywords': !(0, runtime_1.exists)(json, 'seoKeywords') ? undefined : json['seoKeywords'],
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : (json['tags'].map(_1.TagFromJSON)),
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'useTagsAsSeoKeywords': !(0, runtime_1.exists)(json, 'useTagsAsSeoKeywords') ? undefined : json['useTagsAsSeoKeywords'],
        'counter': !(0, runtime_1.exists)(json, 'counter') ? undefined : json['counter'],
        'cast': !(0, runtime_1.exists)(json, 'cast') ? undefined : (0, _1.CastFromJSON)(json['cast']),
        'site': !(0, runtime_1.exists)(json, 'site') ? undefined : (0, _1.SiteDataFromJSON)(json['site']),
        'timeFrom': !(0, runtime_1.exists)(json, 'timeFrom') ? undefined : (new Date(json['timeFrom'])),
        'timeFromRaw': !(0, runtime_1.exists)(json, 'timeFrom') ? undefined : (json['timeFrom']),
        'timeUntil': !(0, runtime_1.exists)(json, 'timeUntil') ? undefined : (new Date(json['timeUntil'])),
        'timeUntilRaw': !(0, runtime_1.exists)(json, 'timeUntil') ? undefined : (json['timeUntil']),
        'releaseDate': !(0, runtime_1.exists)(json, 'releaseDate') ? undefined : (new Date(json['releaseDate'])),
        'releaseDateRaw': !(0, runtime_1.exists)(json, 'releaseDate') ? undefined : (json['releaseDate']),
        'intId': !(0, runtime_1.exists)(json, 'intId') ? undefined : json['intId'],
        'totemImageUrl': !(0, runtime_1.exists)(json, 'totemImageUrl') ? undefined : json['totemImageUrl'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'alternativeLead': !(0, runtime_1.exists)(json, 'alternativeLead') ? undefined : json['alternativeLead'],
        'headingTag': !(0, runtime_1.exists)(json, 'headingTag') ? undefined : (0, _1.HeadingTagFromJSON)(json['headingTag']),
        'alternativeTitle': !(0, runtime_1.exists)(json, 'alternativeTitle') ? undefined : json['alternativeTitle'],
        'widgets': !(0, runtime_1.exists)(json, 'widgets') ? undefined : (json['widgets'] === null ? null : (0, runtime_1.mapValues)(json['widgets'], _1.BoxFromJSON)),
        'recommendedVideosEnable': !(0, runtime_1.exists)(json, 'recommendedVideosEnable') ? undefined : json['recommendedVideosEnable'],
        'disclaimer': !(0, runtime_1.exists)(json, 'disclaimer') ? undefined : json['disclaimer'],
    };
}
exports.ContentDataFromJSONTyped = ContentDataFromJSONTyped;
function ContentDataToJSON(value) {
    return ContentDataToJSONTyped(value, false);
}
exports.ContentDataToJSON = ContentDataToJSON;
function ContentDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': value.active,
        'ageRating': value.ageRating,
        'authors': value.authors === undefined ? undefined : (value.authors.map(_1.AuthorToJSON)),
        'body': value.body,
        'contentType': (0, _1.ContentTypeToJSON)(value.contentType),
        'createdTs': value.createdTs === undefined ? undefined : (value.createdTs.toISOString()),
        'id': value.id,
        'lead': value.lead,
        'leadImageUrls': (0, _1.LeadImageUrlsToJSON)(value.leadImageUrls),
        'modifiedTs': value.modifiedTs === undefined ? undefined : (value.modifiedTs.toISOString()),
        'nsfwFlag': value.nsfwFlag,
        'outerUrl': value.outerUrl,
        'params': (0, _1.ContentParamsToJSON)(value.params),
        'seoKeywords': value.seoKeywords,
        'tags': value.tags === undefined ? undefined : (value.tags.map(_1.TagToJSON)),
        'title': value.title,
        'url': value.url,
        'useTagsAsSeoKeywords': value.useTagsAsSeoKeywords,
        'counter': value.counter,
        'cast': (0, _1.CastToJSON)(value.cast),
        'site': (0, _1.SiteDataToJSON)(value.site),
        'timeFrom': value.timeFrom === undefined ? undefined : (value.timeFrom.toISOString()),
        'timeUntil': value.timeUntil === undefined ? undefined : (value.timeUntil.toISOString()),
        'releaseDate': value.releaseDate === undefined ? undefined : (value.releaseDate.toISOString()),
        'intId': value.intId,
        'totemImageUrl': value.totemImageUrl,
        'label': value.label,
        'alternativeLead': value.alternativeLead,
        'headingTag': (0, _1.HeadingTagToJSON)(value.headingTag),
        'alternativeTitle': value.alternativeTitle,
        'widgets': value.widgets === undefined ? undefined : (value.widgets === null ? null : (0, runtime_1.mapValues)(value.widgets, _1.BoxToJSON)),
        'recommendedVideosEnable': value.recommendedVideosEnable,
        'disclaimer': value.disclaimer,
    };
}
exports.ContentDataToJSONTyped = ContentDataToJSONTyped;
