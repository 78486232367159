"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchContentTypesEnum = exports.PostQuizGdprContributionEnum = exports.ListRelatedContentsNsfwContentEnum = exports.ListRelatedContentsWithVideoEnum = exports.ListRelatedContentsIntervalEnum = exports.ListRelatedContentsAdultContentEnum = exports.ListRelatedContentsRecursiveEnum = exports.ListRelatedContentsContentTypesEnum = exports.ListDefaultContentsNsfwContentEnum = exports.ListDefaultContentsWithVideoEnum = exports.ListDefaultContentsGivenContentsFirstEnum = exports.ListDefaultContentsIntervalEnum = exports.ListDefaultContentsAdultContentEnum = exports.ListDefaultContentsRecursiveEnum = exports.ListDefaultContentsContentTypesEnum = exports.GetContentByIdPresetEnum = exports.ContentApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ContentApi extends runtime.BaseAPI {
    /**
     * List Content feed.
     * Content Feed
     */
    async contentFeedRaw(requestParameters, initOverrides) {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId', 'Required parameter requestParameters.contentId was null or undefined when calling contentFeed.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.totalCount !== undefined) {
            queryParameters['totalCount'] = requestParameters.totalCount;
        }
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/content/{contentId}/feed`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ContentListResultFromJSON)(jsonValue));
    }
    /**
     * List Content feed.
     * Content Feed
     */
    async contentFeed(requestParameters, initOverrides) {
        const response = await this.contentFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets the details of a single instance of a `Content`.
     * Get a Content
     */
    async getContentByIdRaw(requestParameters, initOverrides) {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId', 'Required parameter requestParameters.contentId was null or undefined when calling getContentById.');
        }
        const queryParameters = {};
        if (requestParameters.preset !== undefined) {
            queryParameters['preset'] = requestParameters.preset;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/content/{contentId}`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ContentResultFromJSON)(jsonValue));
    }
    /**
     * Gets the details of a single instance of a `Content`.
     * Get a Content
     */
    async getContentById(requestParameters, initOverrides) {
        const response = await this.getContentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets list of the Contents.
     * List Default Contents
     */
    async listDefaultContentsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.contentTypes) {
            queryParameters['contentTypes'] = requestParameters.contentTypes;
        }
        if (requestParameters.order) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.recursive !== undefined) {
            queryParameters['recursive'] = requestParameters.recursive;
        }
        if (requestParameters.adultContent !== undefined) {
            queryParameters['adultContent'] = requestParameters.adultContent;
        }
        if (requestParameters.folderIds) {
            queryParameters['folderIds'] = requestParameters.folderIds;
        }
        if (requestParameters.contentIds) {
            queryParameters['contentIds'] = requestParameters.contentIds;
        }
        if (requestParameters.filterTags) {
            queryParameters['filterTags'] = requestParameters.filterTags;
        }
        if (requestParameters.cast !== undefined) {
            queryParameters['cast'] = requestParameters.cast;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['channel'] = requestParameters.channel;
        }
        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom.toISOString().substr(0, 10);
        }
        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo.toISOString().substr(0, 10);
        }
        if (requestParameters.priority !== undefined) {
            queryParameters['priority'] = requestParameters.priority;
        }
        if (requestParameters.season !== undefined) {
            queryParameters['season'] = requestParameters.season;
        }
        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }
        if (requestParameters.position !== undefined) {
            queryParameters['position'] = requestParameters.position;
        }
        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }
        if (requestParameters.totalCount !== undefined) {
            queryParameters['totalCount'] = requestParameters.totalCount;
        }
        if (requestParameters.givenContentsFirst !== undefined) {
            queryParameters['givenContentsFirst'] = requestParameters.givenContentsFirst;
        }
        if (requestParameters.publicationDate !== undefined) {
            queryParameters['publicationDate'] = requestParameters.publicationDate.toISOString();
        }
        if (requestParameters.excludedContentIds) {
            queryParameters['excludedContentIds'] = requestParameters.excludedContentIds;
        }
        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }
        if (requestParameters.withVideo !== undefined) {
            queryParameters['withVideo'] = requestParameters.withVideo;
        }
        if (requestParameters.headingTags) {
            queryParameters['headingTags'] = requestParameters.headingTags;
        }
        if (requestParameters.authorSlug !== undefined) {
            queryParameters['authorSlug'] = requestParameters.authorSlug;
        }
        if (requestParameters.nsfwContent !== undefined) {
            queryParameters['nsfwContent'] = requestParameters.nsfwContent;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/content`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ContentListResultFromJSON)(jsonValue));
    }
    /**
     * Gets list of the Contents.
     * List Default Contents
     */
    async listDefaultContents(requestParameters, initOverrides) {
        const response = await this.listDefaultContentsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets list of the Contents.
     * List Related Contents
     */
    async listRelatedContentsRaw(requestParameters, initOverrides) {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId', 'Required parameter requestParameters.contentId was null or undefined when calling listRelatedContents.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.contentTypes) {
            queryParameters['contentTypes'] = requestParameters.contentTypes;
        }
        if (requestParameters.recursive !== undefined) {
            queryParameters['recursive'] = requestParameters.recursive;
        }
        if (requestParameters.adultContent !== undefined) {
            queryParameters['adultContent'] = requestParameters.adultContent;
        }
        if (requestParameters.filterTags) {
            queryParameters['filterTags'] = requestParameters.filterTags;
        }
        if (requestParameters.cast !== undefined) {
            queryParameters['cast'] = requestParameters.cast;
        }
        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom.toISOString().substr(0, 10);
        }
        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo.toISOString().substr(0, 10);
        }
        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }
        if (requestParameters.priority !== undefined) {
            queryParameters['priority'] = requestParameters.priority;
        }
        if (requestParameters.order) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }
        if (requestParameters.totalCount !== undefined) {
            queryParameters['totalCount'] = requestParameters.totalCount;
        }
        if (requestParameters.publicationDate !== undefined) {
            queryParameters['publicationDate'] = requestParameters.publicationDate.toISOString();
        }
        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }
        if (requestParameters.withVideo !== undefined) {
            queryParameters['withVideo'] = requestParameters.withVideo;
        }
        if (requestParameters.authorSlug !== undefined) {
            queryParameters['authorSlug'] = requestParameters.authorSlug;
        }
        if (requestParameters.excludedContentIds) {
            queryParameters['excludedContentIds'] = requestParameters.excludedContentIds;
        }
        if (requestParameters.nsfwContent !== undefined) {
            queryParameters['nsfwContent'] = requestParameters.nsfwContent;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/content/{contentId}/related`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ContentListResultFromJSON)(jsonValue));
    }
    /**
     * Gets list of the Contents.
     * List Related Contents
     */
    async listRelatedContents(requestParameters, initOverrides) {
        const response = await this.listRelatedContentsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Post a Quiz answer
     */
    async postQuizRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.quizId !== undefined) {
            formParams.append('quizId', requestParameters.quizId);
        }
        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email);
        }
        if (requestParameters.phone !== undefined) {
            formParams.append('phone', requestParameters.phone);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.gdprContribution !== undefined) {
            formParams.append('gdprContribution', requestParameters.gdprContribution);
        }
        if (requestParameters.gRecaptchaResponse !== undefined) {
            formParams.append('g-recaptcha-response', requestParameters.gRecaptchaResponse);
        }
        if (requestParameters.userId !== undefined) {
            formParams.append('userId', requestParameters.userId);
        }
        const response = await this.request({
            path: `/quiz`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.QuizResultFromJSON)(jsonValue));
    }
    /**
     * Post a Quiz answer
     */
    async postQuiz(requestParameters, initOverrides) {
        const response = await this.postQuizRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Post a Vote answer
     */
    async postVoteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.voteId !== undefined) {
            formParams.append('voteId', requestParameters.voteId);
        }
        if (requestParameters.answerId !== undefined) {
            formParams.append('answerId', requestParameters.answerId);
        }
        const response = await this.request({
            path: `/vote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.VoteResultFromJSON)(jsonValue));
    }
    /**
     * Post a Vote answer
     */
    async postVote(requestParameters, initOverrides) {
        const response = await this.postVoteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets list of the Contents.
     * Search Contents
     */
    async searchRaw(requestParameters, initOverrides) {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText', 'Required parameter requestParameters.searchText was null or undefined when calling search.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }
        if (requestParameters.searchMode !== undefined) {
            queryParameters['searchMode'] = requestParameters.searchMode;
        }
        if (requestParameters.contentTypes) {
            queryParameters['contentTypes'] = requestParameters.contentTypes;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/search/{searchText}`.replace(`{${"searchText"}}`, encodeURIComponent(String(requestParameters.searchText))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.SearchResultFromJSON)(jsonValue));
    }
    /**
     * Gets list of the Contents.
     * Search Contents
     */
    async search(requestParameters, initOverrides) {
        const response = await this.searchRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.ContentApi = ContentApi;
/**
    * @export
    * @enum {string}
    */
var GetContentByIdPresetEnum;
(function (GetContentByIdPresetEnum) {
    GetContentByIdPresetEnum["Full"] = "full";
    GetContentByIdPresetEnum["Minimal"] = "minimal";
})(GetContentByIdPresetEnum = exports.GetContentByIdPresetEnum || (exports.GetContentByIdPresetEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsContentTypesEnum;
(function (ListDefaultContentsContentTypesEnum) {
    ListDefaultContentsContentTypesEnum["News"] = "news";
    ListDefaultContentsContentTypesEnum["Case"] = "case";
    ListDefaultContentsContentTypesEnum["Celeb"] = "celeb";
    ListDefaultContentsContentTypesEnum["Gallery"] = "gallery";
    ListDefaultContentsContentTypesEnum["Longvideo"] = "longvideo";
    ListDefaultContentsContentTypesEnum["Quiz"] = "quiz";
    ListDefaultContentsContentTypesEnum["Vote"] = "vote";
    ListDefaultContentsContentTypesEnum["Promo"] = "promo";
    ListDefaultContentsContentTypesEnum["Character"] = "character";
    ListDefaultContentsContentTypesEnum["MinuteByMinute"] = "minute_by_minute";
})(ListDefaultContentsContentTypesEnum = exports.ListDefaultContentsContentTypesEnum || (exports.ListDefaultContentsContentTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsRecursiveEnum;
(function (ListDefaultContentsRecursiveEnum) {
    ListDefaultContentsRecursiveEnum[ListDefaultContentsRecursiveEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListDefaultContentsRecursiveEnum[ListDefaultContentsRecursiveEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListDefaultContentsRecursiveEnum = exports.ListDefaultContentsRecursiveEnum || (exports.ListDefaultContentsRecursiveEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsAdultContentEnum;
(function (ListDefaultContentsAdultContentEnum) {
    ListDefaultContentsAdultContentEnum[ListDefaultContentsAdultContentEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListDefaultContentsAdultContentEnum[ListDefaultContentsAdultContentEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListDefaultContentsAdultContentEnum = exports.ListDefaultContentsAdultContentEnum || (exports.ListDefaultContentsAdultContentEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsIntervalEnum;
(function (ListDefaultContentsIntervalEnum) {
    ListDefaultContentsIntervalEnum["Last2Hours"] = "last2Hours";
    ListDefaultContentsIntervalEnum["LastDay"] = "lastDay";
    ListDefaultContentsIntervalEnum["LastWeek"] = "lastWeek";
    ListDefaultContentsIntervalEnum["LastMonth"] = "lastMonth";
})(ListDefaultContentsIntervalEnum = exports.ListDefaultContentsIntervalEnum || (exports.ListDefaultContentsIntervalEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsGivenContentsFirstEnum;
(function (ListDefaultContentsGivenContentsFirstEnum) {
    ListDefaultContentsGivenContentsFirstEnum["_0"] = "0";
    ListDefaultContentsGivenContentsFirstEnum["_1"] = "1";
})(ListDefaultContentsGivenContentsFirstEnum = exports.ListDefaultContentsGivenContentsFirstEnum || (exports.ListDefaultContentsGivenContentsFirstEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsWithVideoEnum;
(function (ListDefaultContentsWithVideoEnum) {
    ListDefaultContentsWithVideoEnum[ListDefaultContentsWithVideoEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListDefaultContentsWithVideoEnum[ListDefaultContentsWithVideoEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListDefaultContentsWithVideoEnum = exports.ListDefaultContentsWithVideoEnum || (exports.ListDefaultContentsWithVideoEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListDefaultContentsNsfwContentEnum;
(function (ListDefaultContentsNsfwContentEnum) {
    ListDefaultContentsNsfwContentEnum[ListDefaultContentsNsfwContentEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListDefaultContentsNsfwContentEnum[ListDefaultContentsNsfwContentEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListDefaultContentsNsfwContentEnum = exports.ListDefaultContentsNsfwContentEnum || (exports.ListDefaultContentsNsfwContentEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListRelatedContentsContentTypesEnum;
(function (ListRelatedContentsContentTypesEnum) {
    ListRelatedContentsContentTypesEnum["News"] = "news";
    ListRelatedContentsContentTypesEnum["Case"] = "case";
    ListRelatedContentsContentTypesEnum["Celeb"] = "celeb";
    ListRelatedContentsContentTypesEnum["Gallery"] = "gallery";
    ListRelatedContentsContentTypesEnum["Longvideo"] = "longvideo";
    ListRelatedContentsContentTypesEnum["Quiz"] = "quiz";
    ListRelatedContentsContentTypesEnum["Vote"] = "vote";
    ListRelatedContentsContentTypesEnum["Promo"] = "promo";
    ListRelatedContentsContentTypesEnum["Character"] = "character";
    ListRelatedContentsContentTypesEnum["MinuteByMinute"] = "minute_by_minute";
})(ListRelatedContentsContentTypesEnum = exports.ListRelatedContentsContentTypesEnum || (exports.ListRelatedContentsContentTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListRelatedContentsRecursiveEnum;
(function (ListRelatedContentsRecursiveEnum) {
    ListRelatedContentsRecursiveEnum[ListRelatedContentsRecursiveEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListRelatedContentsRecursiveEnum[ListRelatedContentsRecursiveEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListRelatedContentsRecursiveEnum = exports.ListRelatedContentsRecursiveEnum || (exports.ListRelatedContentsRecursiveEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListRelatedContentsAdultContentEnum;
(function (ListRelatedContentsAdultContentEnum) {
    ListRelatedContentsAdultContentEnum[ListRelatedContentsAdultContentEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListRelatedContentsAdultContentEnum[ListRelatedContentsAdultContentEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListRelatedContentsAdultContentEnum = exports.ListRelatedContentsAdultContentEnum || (exports.ListRelatedContentsAdultContentEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListRelatedContentsIntervalEnum;
(function (ListRelatedContentsIntervalEnum) {
    ListRelatedContentsIntervalEnum["Last2Hours"] = "last2Hours";
    ListRelatedContentsIntervalEnum["LastDay"] = "lastDay";
    ListRelatedContentsIntervalEnum["LastWeek"] = "lastWeek";
    ListRelatedContentsIntervalEnum["LastMonth"] = "lastMonth";
})(ListRelatedContentsIntervalEnum = exports.ListRelatedContentsIntervalEnum || (exports.ListRelatedContentsIntervalEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListRelatedContentsWithVideoEnum;
(function (ListRelatedContentsWithVideoEnum) {
    ListRelatedContentsWithVideoEnum[ListRelatedContentsWithVideoEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListRelatedContentsWithVideoEnum[ListRelatedContentsWithVideoEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListRelatedContentsWithVideoEnum = exports.ListRelatedContentsWithVideoEnum || (exports.ListRelatedContentsWithVideoEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ListRelatedContentsNsfwContentEnum;
(function (ListRelatedContentsNsfwContentEnum) {
    ListRelatedContentsNsfwContentEnum[ListRelatedContentsNsfwContentEnum["NUMBER_0"] = 0] = "NUMBER_0";
    ListRelatedContentsNsfwContentEnum[ListRelatedContentsNsfwContentEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(ListRelatedContentsNsfwContentEnum = exports.ListRelatedContentsNsfwContentEnum || (exports.ListRelatedContentsNsfwContentEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PostQuizGdprContributionEnum;
(function (PostQuizGdprContributionEnum) {
    PostQuizGdprContributionEnum[PostQuizGdprContributionEnum["NUMBER_0"] = 0] = "NUMBER_0";
    PostQuizGdprContributionEnum[PostQuizGdprContributionEnum["NUMBER_1"] = 1] = "NUMBER_1";
})(PostQuizGdprContributionEnum = exports.PostQuizGdprContributionEnum || (exports.PostQuizGdprContributionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SearchContentTypesEnum;
(function (SearchContentTypesEnum) {
    SearchContentTypesEnum["News"] = "news";
    SearchContentTypesEnum["Gallery"] = "gallery";
    SearchContentTypesEnum["Longvideo"] = "longvideo";
    SearchContentTypesEnum["Quiz"] = "quiz";
    SearchContentTypesEnum["Vote"] = "vote";
    SearchContentTypesEnum["Character"] = "character";
})(SearchContentTypesEnum = exports.SearchContentTypesEnum || (exports.SearchContentTypesEnum = {}));
