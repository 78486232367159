"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostMeasurementDataToJSONTyped = exports.PostMeasurementDataToJSON = exports.PostMeasurementDataFromJSONTyped = exports.PostMeasurementDataFromJSON = void 0;
function PostMeasurementDataFromJSON(json) {
    return PostMeasurementDataFromJSONTyped(json, false);
}
exports.PostMeasurementDataFromJSON = PostMeasurementDataFromJSON;
function PostMeasurementDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'eventName': json['eventName'],
        'value': json['value'],
    };
}
exports.PostMeasurementDataFromJSONTyped = PostMeasurementDataFromJSONTyped;
function PostMeasurementDataToJSON(value) {
    return PostMeasurementDataToJSONTyped(value, false);
}
exports.PostMeasurementDataToJSON = PostMeasurementDataToJSON;
function PostMeasurementDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'eventName': value.eventName,
        'value': value.value,
    };
}
exports.PostMeasurementDataToJSONTyped = PostMeasurementDataToJSONTyped;
