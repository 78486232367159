"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorListResultToJSONTyped = exports.AuthorListResultToJSON = exports.AuthorListResultFromJSONTyped = exports.AuthorListResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthorListResultFromJSON(json) {
    return AuthorListResultFromJSONTyped(json, false);
}
exports.AuthorListResultFromJSON = AuthorListResultFromJSON;
function AuthorListResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (0, _1.AuthorListDataFromJSON)(json['data']),
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : (0, _1.ErrorDataFromJSON)(json['error']),
        'result': (0, _1.ResultTypeFromJSON)(json['result']),
        'status': (0, _1.HTTPStatusCodeFromJSON)(json['status']),
    };
}
exports.AuthorListResultFromJSONTyped = AuthorListResultFromJSONTyped;
function AuthorListResultToJSON(value) {
    return AuthorListResultToJSONTyped(value, false);
}
exports.AuthorListResultToJSON = AuthorListResultToJSON;
function AuthorListResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (0, _1.AuthorListDataToJSON)(value.data),
        'error': (0, _1.ErrorDataToJSON)(value.error),
        'result': (0, _1.ResultTypeToJSON)(value.result),
        'status': (0, _1.HTTPStatusCodeToJSON)(value.status),
    };
}
exports.AuthorListResultToJSONTyped = AuthorListResultToJSONTyped;
