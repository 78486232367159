import { Options, Prop, Vue } from 'vue-property-decorator'

import { WidgetMeta } from './WidgetMixin'

@Options({
  name: 'WidgetStyleMixin'
})
export default class WidgetStyleMixin extends Vue {
  @Prop({
    type: String,
    default: null
  })
  readonly bgColor!: string

  @Prop({
    type: String,
    default: null
  })
  readonly logoUrl!: string

  @Prop({
    type: String,
    default: null
  })
  readonly sponsorText!: string

  get widgetStyleBinding () {
    return {
      bgColor: this.bgColor,
      logoUrl: this.logoUrl,
      sponsorText: this.sponsorText
    }
  }
}

export const widgetStyleFields: WidgetMeta['fields'] = {
  bgColor: {
    type: 'color',
    props: {
      label: 'Háttérszín'
    }
  },
  logoUrl: {
    type: String,
    props: {
      placeholder: 'Szponzorált kép (url)'
    }
  },
  sponsorText: {
    type: String,
    props: {
      placeholder: 'Fejléc szöveg'
    }
  }
}
