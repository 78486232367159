"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingFormFieldToJSONTyped = exports.CastingFormFieldToJSON = exports.CastingFormFieldFromJSONTyped = exports.CastingFormFieldFromJSON = exports.CastingFormFieldTypeEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var CastingFormFieldTypeEnum;
(function (CastingFormFieldTypeEnum) {
    CastingFormFieldTypeEnum["Checkbox"] = "checkbox";
    CastingFormFieldTypeEnum["File"] = "file";
    CastingFormFieldTypeEnum["Text"] = "text";
    CastingFormFieldTypeEnum["Textarea"] = "textarea";
    CastingFormFieldTypeEnum["Select"] = "select";
})(CastingFormFieldTypeEnum = exports.CastingFormFieldTypeEnum || (exports.CastingFormFieldTypeEnum = {}));
function CastingFormFieldFromJSON(json) {
    return CastingFormFieldFromJSONTyped(json, false);
}
exports.CastingFormFieldFromJSON = CastingFormFieldFromJSON;
function CastingFormFieldFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'attributes': !(0, runtime_1.exists)(json, 'attributes') ? undefined : json['attributes'],
        'label': json['label'],
        'name': json['name'],
        'type': json['type'],
        'placeholder': !(0, runtime_1.exists)(json, 'placeholder') ? undefined : json['placeholder'],
        'defaultValue': json['defaultValue'],
    };
}
exports.CastingFormFieldFromJSONTyped = CastingFormFieldFromJSONTyped;
function CastingFormFieldToJSON(value) {
    return CastingFormFieldToJSONTyped(value, false);
}
exports.CastingFormFieldToJSON = CastingFormFieldToJSON;
function CastingFormFieldToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'attributes': value.attributes,
        'label': value.label,
        'name': value.name,
        'type': value.type,
        'placeholder': value.placeholder,
        'defaultValue': value.defaultValue,
    };
}
exports.CastingFormFieldToJSONTyped = CastingFormFieldToJSONTyped;
