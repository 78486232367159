
import { Options, Prop, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'

import { isValidUrl } from '../../../utils'
import ArrowRight from '../icons/ArrowRight.vue'
import SliderLeft from '../icons/SliderLeft.vue'
import SliderRight from '../icons/SliderRight.vue'
import NavLink from '../NavLink.vue'

@Options({
  name: 'WidgetBlock',
  components: {
    NavLink,
    ArrowRight,
    SliderLeft,
    SliderRight
  }
})
export default class WidgetBlock extends Vue {
  @Prop({ type: String, default: '' })
  readonly title!: string

  @Prop({ type: String, default: '' })
  readonly btnLabel!: string

  @Prop({ type: [String, Object], default: '' })
  readonly btnUrl!: RouteLocationRaw

  @Prop({ type: Boolean, default: true })
  readonly showDivider!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showTitle!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showButton!: boolean

  @Prop({ type: Boolean, default: false })
  readonly showSlider!: boolean

  @Prop({ type: String, default: '' })
  readonly blockClass!: string

  @Prop({ type: String, default: null })
  readonly bgColor!: string

  @Prop({ type: String, default: null })
  readonly logoUrl!: string

  @Prop({ type: String, default: null })
  readonly sponsorText!: string

  @Ref('widget')
  widget!: HTMLDivElement

  @Watch('logoUrl', { immediate: true })
  onLogoUrlChange () {
    this.validLogoUrl = isValidUrl(this.logoUrl) ? this.logoUrl : null
  }

  get hasTitle (): boolean {
    return !!this.title
  }

  get hasButton (): boolean {
    return !!this.btnLabel && !!this.btnUrl
  }

  maxLeft = true
  maxRight = false
  showSliderArrows = false
  validLogoUrl: string | null = null

  getSlider () {
    return this.widget?.querySelector('[data-slider-pane]')
  }

  @Watch('showSlider')
  checkSliderArrows () {
    if (this.showSlider) {
      setTimeout(() => {
        const slider = this.getSlider()
        this.showSliderArrows = slider ? slider.scrollWidth > slider.clientWidth : false
      })
    }
  }

  getSliderWidth () {
    const slider = this.getSlider()

    return slider && slider.getBoundingClientRect
      ? slider.getBoundingClientRect().width
      : 0
  }

  scroll (direction: 'left' | 'right' = 'left') {
    const slider = this.getSlider()

    if (slider) {
      const orient = direction === 'left' ? -1 : 1
      const leap = this.getSliderWidth() * orient
      const left = slider.scrollLeft + leap

      slider.scrollTo({ left, behavior: 'smooth' })
    }
  }

  scrollHandler () {
    const slider = this.getSlider()

    if (slider) {
      this.maxLeft = slider.scrollLeft === 0
      this.maxRight = slider.scrollWidth === (slider.scrollLeft + this.getSliderWidth())
    }
  }

  isContrast () {
    if (this.bgColor) {
      const rgb = this.hexToRgb(this.bgColor)
      if (rgb) {
        return ((Math.round(rgb.r * 299) + Math.round(rgb.g * 587) + Math.round(rgb.b * 114)) / 1000) >= 160
      }
    }
    return true
  }

  hexToRgb (hex: string): {
    r: number
    g: number
    b: number
  } | null {
    const result = /#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  mounted () {
    if (this.showSlider) {
      const slider = this.getSlider()
      slider?.addEventListener('scroll', this.scrollHandler)
      this.checkSliderArrows()
    }
  }

  @Provide() utmMedium = this.$attrs['data-utm-medium'] || null
  @Provide() utmSource = this.$attrs['data-utm-source'] || null
  @Provide() utmCampaign = this.$attrs['data-utm-campaign'] || null

  unmounted () {
    if (this.showSlider) {
      const slider = this.getSlider()
      slider?.removeEventListener('scroll', this.scrollHandler)
    }
  }
}
