"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class EventApi extends runtime.BaseAPI {
    /**
     * Post an event
     */
    async postEventRaw(requestParameters, initOverrides) {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId', 'Required parameter requestParameters.contentId was null or undefined when calling postEvent.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/event/{contentId}`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.EventResultFromJSON)(jsonValue));
    }
    /**
     * Post an event
     */
    async postEvent(requestParameters, initOverrides) {
        const response = await this.postEventRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.EventApi = EventApi;
