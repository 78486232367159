export const FAQ_NAMESPACE = 'faq'

export interface FaqState {
  data: Array<FaqTopic>
  selectedTopic: string | string[] | null
  selectedSubTopic: string | null
  selectedQuestion: string | null
}

export enum FAQ_GETTERS {
  GET_DATA = 'getFaqData',
  GET_TOPIC = 'getTopic',
  GET_TOPIC_NAMES = 'getTopicNames',
  GET_QUESTION = 'getQuestion',
}

export enum FAQ_MUTATION {
  SET_DATA = 'setFaqData',
  SET_TOPIC = 'changeSelectedTopic',
  SET_SUBTOPIC = 'changeSelectedSubtopic',
  SET_QUESTION = 'changeSelectedQuestion',
}

type service = 'light' | 'active' | 'sandokan' | 'rtlhu' | 'linear' | 'common'

export interface FaqTopic {
  label: string
  subTopics: Array<FaqSubTopic>
  tag: string
  icon: string
}

export interface FaqSubTopic {
  label: string | null
  questions: Array<FaqQA>
  tag: string | null
}

export interface FaqQA {
  question: string
  answer: Record<service, string | null>
  tag: string
}



