"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqSubtopicToJSONTyped = exports.FaqSubtopicToJSON = exports.FaqSubtopicFromJSONTyped = exports.FaqSubtopicFromJSON = void 0;
const _1 = require("./");
function FaqSubtopicFromJSON(json) {
    return FaqSubtopicFromJSONTyped(json, false);
}
exports.FaqSubtopicFromJSON = FaqSubtopicFromJSON;
function FaqSubtopicFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': json['label'],
        'tag': json['tag'],
        'questions': (json['questions'].map(_1.FaqSubtopicQuestionFromJSON)),
    };
}
exports.FaqSubtopicFromJSONTyped = FaqSubtopicFromJSONTyped;
function FaqSubtopicToJSON(value) {
    return FaqSubtopicToJSONTyped(value, false);
}
exports.FaqSubtopicToJSON = FaqSubtopicToJSON;
function FaqSubtopicToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'tag': value.tag,
        'questions': (value.questions.map(_1.FaqSubtopicQuestionToJSON)),
    };
}
exports.FaqSubtopicToJSONTyped = FaqSubtopicToJSONTyped;
