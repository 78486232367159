"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchContentsToJSONTyped = exports.SearchContentsToJSON = exports.SearchContentsFromJSONTyped = exports.SearchContentsFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SearchContentsFromJSON(json) {
    return SearchContentsFromJSONTyped(json, false);
}
exports.SearchContentsFromJSON = SearchContentsFromJSON;
function SearchContentsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.ContentDataFromJSON)),
    };
}
exports.SearchContentsFromJSONTyped = SearchContentsFromJSONTyped;
function SearchContentsToJSON(value) {
    return SearchContentsToJSONTyped(value, false);
}
exports.SearchContentsToJSON = SearchContentsToJSON;
function SearchContentsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'totalCount': value.totalCount,
        'items': value.items === undefined ? undefined : (value.items.map(_1.ContentDataToJSON)),
    };
}
exports.SearchContentsToJSONTyped = SearchContentsToJSONTyped;
