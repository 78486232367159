"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteListDataToJSONTyped = exports.SiteListDataToJSON = exports.SiteListDataFromJSONTyped = exports.SiteListDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SiteListDataFromJSON(json) {
    return SiteListDataFromJSONTyped(json, false);
}
exports.SiteListDataFromJSON = SiteListDataFromJSON;
function SiteListDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, _1.ContentListDataPayloadFromJSON)(json['payload']),
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.SiteDataFromJSON)),
    };
}
exports.SiteListDataFromJSONTyped = SiteListDataFromJSONTyped;
function SiteListDataToJSON(value) {
    return SiteListDataToJSONTyped(value, false);
}
exports.SiteListDataToJSON = SiteListDataToJSON;
function SiteListDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'totalCount': value.totalCount,
        'payload': (0, _1.ContentListDataPayloadToJSON)(value.payload),
        'items': value.items === undefined ? undefined : (value.items.map(_1.SiteDataToJSON)),
    };
}
exports.SiteListDataToJSONTyped = SiteListDataToJSONTyped;
