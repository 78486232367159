"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPageDataToJSONTyped = exports.AdminPageDataToJSON = exports.AdminPageDataFromJSONTyped = exports.AdminPageDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminPageDataFromJSON(json) {
    return AdminPageDataFromJSONTyped(json, false);
}
exports.AdminPageDataFromJSON = AdminPageDataFromJSON;
function AdminPageDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'layoutId': !(0, runtime_1.exists)(json, 'layoutId') ? undefined : json['layoutId'],
        'urlFragment': !(0, runtime_1.exists)(json, 'urlFragment') ? undefined : json['urlFragment'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'label': json['label'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'sitemap': !(0, runtime_1.exists)(json, 'sitemap') ? undefined : json['sitemap'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'createdBy': !(0, runtime_1.exists)(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTs': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (new Date(json['createdTs'])),
        'createdTsRaw': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (json['createdTs']),
        'modifiedBy': !(0, runtime_1.exists)(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedTs': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (new Date(json['modifiedTs'])),
        'modifiedTsRaw': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (json['modifiedTs']),
        'layoutFile': !(0, runtime_1.exists)(json, 'layoutFile') ? undefined : json['layoutFile'],
        'lockedBy': !(0, runtime_1.exists)(json, 'lockedBy') ? undefined : json['lockedBy'],
        'lockedEnd': !(0, runtime_1.exists)(json, 'lockedEnd') ? undefined : (new Date(json['lockedEnd'])),
        'lockedEndRaw': !(0, runtime_1.exists)(json, 'lockedEnd') ? undefined : (json['lockedEnd']),
        'isConnectedWithLayout': !(0, runtime_1.exists)(json, 'isConnectedWithLayout') ? undefined : json['isConnectedWithLayout'],
        'folderParameters': !(0, runtime_1.exists)(json, 'folderParameters') ? undefined : json['folderParameters'],
        'skeleton': (json['skeleton'].map(_1.BoxFromJSON)),
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'site': !(0, runtime_1.exists)(json, 'site') ? undefined : (0, _1.SiteDataFromJSON)(json['site']),
        'html': json['html'],
    };
}
exports.AdminPageDataFromJSONTyped = AdminPageDataFromJSONTyped;
function AdminPageDataToJSON(value) {
    return AdminPageDataToJSONTyped(value, false);
}
exports.AdminPageDataToJSON = AdminPageDataToJSON;
function AdminPageDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'folderId': value.folderId,
        'layoutId': value.layoutId,
        'urlFragment': value.urlFragment,
        'url': value.url,
        'label': value.label,
        'title': value.title,
        'sitemap': value.sitemap,
        'active': value.active,
        'createdBy': value.createdBy,
        'createdTs': value.createdTs === undefined ? undefined : (value.createdTs.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedTs': value.modifiedTs === undefined ? undefined : (value.modifiedTs.toISOString()),
        'layoutFile': value.layoutFile,
        'lockedBy': value.lockedBy,
        'lockedEnd': value.lockedEnd === undefined ? undefined : (value.lockedEnd.toISOString()),
        'isConnectedWithLayout': value.isConnectedWithLayout,
        'folderParameters': value.folderParameters,
        'skeleton': (value.skeleton.map(_1.BoxToJSON)),
        'description': value.description,
        'site': (0, _1.SiteDataToJSON)(value.site),
        'html': value.html,
    };
}
exports.AdminPageDataToJSONTyped = AdminPageDataToJSONTyped;
