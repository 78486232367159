"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorToJSONTyped = exports.AuthorToJSON = exports.AuthorFromJSONTyped = exports.AuthorFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthorFromJSON(json) {
    return AuthorFromJSONTyped(json, false);
}
exports.AuthorFromJSON = AuthorFromJSON;
function AuthorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'id': json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'associated': !(0, runtime_1.exists)(json, 'associated') ? undefined : json['associated'],
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
    };
}
exports.AuthorFromJSONTyped = AuthorFromJSONTyped;
function AuthorToJSON(value) {
    return AuthorToJSONTyped(value, false);
}
exports.AuthorToJSON = AuthorToJSON;
function AuthorToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'position': value.position,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'associated': value.associated,
        'slug': value.slug,
    };
}
exports.AuthorToJSONTyped = AuthorToJSONTyped;
