"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterToJSONTyped = exports.CharacterToJSON = exports.CharacterFromJSONTyped = exports.CharacterFromJSON = void 0;
const runtime_1 = require("../runtime");
function CharacterFromJSON(json) {
    return CharacterFromJSONTyped(json, false);
}
exports.CharacterFromJSON = CharacterFromJSON;
function CharacterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'siteName': !(0, runtime_1.exists)(json, 'siteName') ? undefined : json['siteName'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
    };
}
exports.CharacterFromJSONTyped = CharacterFromJSONTyped;
function CharacterToJSON(value) {
    return CharacterToJSONTyped(value, false);
}
exports.CharacterToJSON = CharacterToJSON;
function CharacterToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'siteName': value.siteName,
        'url': value.url,
    };
}
exports.CharacterToJSONTyped = CharacterToJSONTyped;
