"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentTypeToJSONTyped = exports.ContentTypeToJSON = exports.ContentTypeFromJSONTyped = exports.ContentTypeFromJSON = exports.ContentType = void 0;
/**
 * Type of content
 * @export
 * @enum {string}
 */
var ContentType;
(function (ContentType) {
    ContentType["News"] = "news";
    ContentType["Case"] = "case";
    ContentType["Celeb"] = "celeb";
    ContentType["FolderContent"] = "folder_content";
    ContentType["Gallery"] = "gallery";
    ContentType["Longvideo"] = "longvideo";
    ContentType["Quiz"] = "quiz";
    ContentType["Vote"] = "vote";
    ContentType["Promo"] = "promo";
    ContentType["Character"] = "character";
    ContentType["MinuteByMinute"] = "minute_by_minute";
    ContentType["Post"] = "post";
})(ContentType = exports.ContentType || (exports.ContentType = {}));
function ContentTypeFromJSON(json) {
    return ContentTypeFromJSONTyped(json, false);
}
exports.ContentTypeFromJSON = ContentTypeFromJSON;
function ContentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ContentTypeFromJSONTyped = ContentTypeFromJSONTyped;
function ContentTypeToJSON(value) {
    return ContentTypeToJSONTyped(value, false);
}
exports.ContentTypeToJSON = ContentTypeToJSON;
function ContentTypeToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.ContentTypeToJSONTyped = ContentTypeToJSONTyped;
