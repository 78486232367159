"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PageApi extends runtime.BaseAPI {
    /**
     * Get page by hostname/url
     * Get page by hostname/url
     */
    async getPageByUrlRaw(requestParameters, initOverrides) {
        if (requestParameters.hostname === null || requestParameters.hostname === undefined) {
            throw new runtime.RequiredError('hostname', 'Required parameter requestParameters.hostname was null or undefined when calling getPageByUrl.');
        }
        if (requestParameters.url === null || requestParameters.url === undefined) {
            throw new runtime.RequiredError('url', 'Required parameter requestParameters.url was null or undefined when calling getPageByUrl.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/page/{hostname}/{url}`.replace(`{${"hostname"}}`, encodeURIComponent(String(requestParameters.hostname))).replace(`{${"url"}}`, encodeURIComponent(String(requestParameters.url))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.PageResultFromJSON)(jsonValue));
    }
    /**
     * Get page by hostname/url
     * Get page by hostname/url
     */
    async getPageByUrl(requestParameters, initOverrides) {
        const response = await this.getPageByUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.PageApi = PageApi;
