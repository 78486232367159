"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoteAnswerResultToJSONTyped = exports.VoteAnswerResultToJSON = exports.VoteAnswerResultFromJSONTyped = exports.VoteAnswerResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function VoteAnswerResultFromJSON(json) {
    return VoteAnswerResultFromJSONTyped(json, false);
}
exports.VoteAnswerResultFromJSON = VoteAnswerResultFromJSON;
function VoteAnswerResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'answer': !(0, runtime_1.exists)(json, 'answer') ? undefined : json['answer'],
        'counter': !(0, runtime_1.exists)(json, 'counter') ? undefined : json['counter'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
    };
}
exports.VoteAnswerResultFromJSONTyped = VoteAnswerResultFromJSONTyped;
function VoteAnswerResultToJSON(value) {
    return VoteAnswerResultToJSONTyped(value, false);
}
exports.VoteAnswerResultToJSON = VoteAnswerResultToJSON;
function VoteAnswerResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'answer': value.answer,
        'counter': value.counter,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
    };
}
exports.VoteAnswerResultToJSONTyped = VoteAnswerResultToJSONTyped;
