"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqDataToJSONTyped = exports.FaqDataToJSON = exports.FaqDataFromJSONTyped = exports.FaqDataFromJSON = void 0;
const _1 = require("./");
function FaqDataFromJSON(json) {
    return FaqDataFromJSONTyped(json, false);
}
exports.FaqDataFromJSON = FaqDataFromJSON;
function FaqDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'incidentBanner': json['incidentBanner'],
        'topics': (json['topics'].map(_1.FaqTopicFromJSON)),
    };
}
exports.FaqDataFromJSONTyped = FaqDataFromJSONTyped;
function FaqDataToJSON(value) {
    return FaqDataToJSONTyped(value, false);
}
exports.FaqDataToJSON = FaqDataToJSON;
function FaqDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'incidentBanner': value.incidentBanner,
        'topics': (value.topics.map(_1.FaqTopicToJSON)),
    };
}
exports.FaqDataToJSONTyped = FaqDataToJSONTyped;
