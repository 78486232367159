"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminUserDataToJSONTyped = exports.AdminUserDataToJSON = exports.AdminUserDataFromJSONTyped = exports.AdminUserDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminUserDataFromJSON(json) {
    return AdminUserDataFromJSONTyped(json, false);
}
exports.AdminUserDataFromJSON = AdminUserDataFromJSON;
function AdminUserDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'privileges': ((0, runtime_1.mapValues)(json['privileges'], _1.PrivilegeFromJSON)),
        'accessToken': !(0, runtime_1.exists)(json, 'accessToken') ? undefined : json['accessToken'],
        'accessTokenExpiration': !(0, runtime_1.exists)(json, 'accessTokenExpiration') ? undefined : (new Date(json['accessTokenExpiration'])),
        'accessTokenExpirationRaw': !(0, runtime_1.exists)(json, 'accessTokenExpiration') ? undefined : (json['accessTokenExpiration']),
        'authorizationCode': !(0, runtime_1.exists)(json, 'authorizationCode') ? undefined : json['authorizationCode'],
    };
}
exports.AdminUserDataFromJSONTyped = AdminUserDataFromJSONTyped;
function AdminUserDataToJSON(value) {
    return AdminUserDataToJSONTyped(value, false);
}
exports.AdminUserDataToJSON = AdminUserDataToJSON;
function AdminUserDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'privileges': ((0, runtime_1.mapValues)(value.privileges, _1.PrivilegeToJSON)),
        'accessToken': value.accessToken,
        'accessTokenExpiration': value.accessTokenExpiration === undefined ? undefined : (value.accessTokenExpiration.toISOString()),
        'authorizationCode': value.authorizationCode,
    };
}
exports.AdminUserDataToJSONTyped = AdminUserDataToJSONTyped;
