"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentParamsToJSONTyped = exports.ContentParamsToJSON = exports.ContentParamsFromJSONTyped = exports.ContentParamsFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ContentParamsFromJSON(json) {
    return ContentParamsFromJSONTyped(json, false);
}
exports.ContentParamsFromJSON = ContentParamsFromJSON;
function ContentParamsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'season': !(0, runtime_1.exists)(json, 'season') ? undefined : json['season'],
        'episode': !(0, runtime_1.exists)(json, 'episode') ? undefined : json['episode'],
        'leadDraft': !(0, runtime_1.exists)(json, 'leadDraft') ? undefined : json['leadDraft'],
        'titleDraft': !(0, runtime_1.exists)(json, 'titleDraft') ? undefined : json['titleDraft'],
        'bodyDraft': !(0, runtime_1.exists)(json, 'bodyDraft') ? undefined : json['bodyDraft'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'eyeColor': !(0, runtime_1.exists)(json, 'eyeColor') ? undefined : json['eyeColor'],
        'birthPlace': !(0, runtime_1.exists)(json, 'birthPlace') ? undefined : json['birthPlace'],
        'birthDate': !(0, runtime_1.exists)(json, 'birthDate') ? undefined : json['birthDate'],
        'weight': !(0, runtime_1.exists)(json, 'weight') ? undefined : json['weight'],
        'height': !(0, runtime_1.exists)(json, 'height') ? undefined : json['height'],
        'favouriteColor': !(0, runtime_1.exists)(json, 'favouriteColor') ? undefined : json['favouriteColor'],
        'favouriteFood': !(0, runtime_1.exists)(json, 'favouriteFood') ? undefined : json['favouriteFood'],
        'favouriteFilm': !(0, runtime_1.exists)(json, 'favouriteFilm') ? undefined : json['favouriteFilm'],
        'favouriteBand': !(0, runtime_1.exists)(json, 'favouriteBand') ? undefined : json['favouriteBand'],
        'zodiac': !(0, runtime_1.exists)(json, 'zodiac') ? undefined : json['zodiac'],
        'additionalJson': !(0, runtime_1.exists)(json, 'additionalJson') ? undefined : (0, _1.AdditionalJsonFromJSON)(json['additionalJson']),
        'duration': !(0, runtime_1.exists)(json, 'duration') ? undefined : json['duration'],
        'anonymQuiz': !(0, runtime_1.exists)(json, 'anonymQuiz') ? undefined : json['anonymQuiz'],
        'qzBadgeMask': !(0, runtime_1.exists)(json, 'qzBadgeMask') ? undefined : json['qzBadgeMask'],
        'qzBadgeScoreLimit': !(0, runtime_1.exists)(json, 'qzBadgeScoreLimit') ? undefined : json['qzBadgeScoreLimit'],
        'qzResubmitMessage': !(0, runtime_1.exists)(json, 'qzResubmitMessage') ? undefined : json['qzResubmitMessage'],
        'qzSubmitFreqMins': !(0, runtime_1.exists)(json, 'qzSubmitFreqMins') ? undefined : json['qzSubmitFreqMins'],
        'qzSubmitInterval': !(0, runtime_1.exists)(json, 'qzSubmitInterval') ? undefined : json['qzSubmitInterval'],
        'qzSubmitIntervalStart': !(0, runtime_1.exists)(json, 'qzSubmitIntervalStart') ? undefined : json['qzSubmitIntervalStart'],
        'questions': !(0, runtime_1.exists)(json, 'questions') ? undefined : (json['questions'].map(_1.QuizQuestionFromJSON)),
        'contentListUrl': !(0, runtime_1.exists)(json, 'contentListUrl') ? undefined : (0, _1.ContentListUrlFromJSON)(json['contentListUrl']),
        'embedVideoUrl': !(0, runtime_1.exists)(json, 'embedVideoUrl') ? undefined : json['embedVideoUrl'],
        'galleryImages': !(0, runtime_1.exists)(json, 'galleryImages') ? undefined : (json['galleryImages'].map(_1.GalleryImageFromJSON)),
        'showVoteResults': !(0, runtime_1.exists)(json, 'showVoteResults') ? undefined : json['showVoteResults'],
        'answers': !(0, runtime_1.exists)(json, 'answers') ? undefined : (json['answers'].map(_1.VoteAnswerFromJSON)),
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
        'contents': !(0, runtime_1.exists)(json, 'contents') ? undefined : (json['contents'].map(_1.ContentDataFromJSON)),
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, _1.ContentListDataPayloadFromJSON)(json['payload']),
        'tabs': !(0, runtime_1.exists)(json, 'tabs') ? undefined : (json['tabs'].map(_1.CelebTabsFromJSON)),
        'publishedOnSixPlay': !(0, runtime_1.exists)(json, 'publishedOnSixPlay') ? undefined : json['publishedOnSixPlay'],
        'rtlMostEpisodeUrl': !(0, runtime_1.exists)(json, 'rtlMostEpisodeUrl') ? undefined : json['rtlMostEpisodeUrl'],
        'rtlMostEpisodeTitle': !(0, runtime_1.exists)(json, 'rtlMostEpisodeTitle') ? undefined : json['rtlMostEpisodeTitle'],
        'celebContentId': !(0, runtime_1.exists)(json, 'celebContentId') ? undefined : json['celebContentId'],
        'fellOut': !(0, runtime_1.exists)(json, 'fellOut') ? undefined : json['fellOut'],
        'episodeDate': !(0, runtime_1.exists)(json, 'episodeDate') ? undefined : (json['episodeDate'] === null ? null : new Date(json['episodeDate'])),
        'episodeDateRaw': !(0, runtime_1.exists)(json, 'episodeDate') ? undefined : (json['episodeDate'] === null ? null : json['episodeDate']),
        'featuredContents': !(0, runtime_1.exists)(json, 'featuredContents') ? undefined : (json['featuredContents'].map(_1.MinimalContentDataFromJSON)),
        'embeddableFeaturedContent': !(0, runtime_1.exists)(json, 'embeddableFeaturedContent') ? undefined : json['embeddableFeaturedContent'],
        'rtlMostEpisode': !(0, runtime_1.exists)(json, 'rtlMostEpisode') ? undefined : json['rtlMostEpisode'],
        'rtlMostSeason': !(0, runtime_1.exists)(json, 'rtlMostSeason') ? undefined : json['rtlMostSeason'],
        'hightlightedTags': !(0, runtime_1.exists)(json, 'hightlightedTags') ? undefined : (json['hightlightedTags'].map(_1.TagFromJSON)),
        'alternativeImageUrls': !(0, runtime_1.exists)(json, 'alternativeImageUrls') ? undefined : (0, _1.AlternativeImageUrlsFromJSON)(json['alternativeImageUrls']),
        'withVideo': !(0, runtime_1.exists)(json, 'withVideo') ? undefined : json['withVideo'],
        'isLiveFeed': !(0, runtime_1.exists)(json, 'isLiveFeed') ? undefined : json['isLiveFeed'],
        'embedGalleryUrl': !(0, runtime_1.exists)(json, 'embedGalleryUrl') ? undefined : json['embedGalleryUrl'],
        'hasAttachedGallery': !(0, runtime_1.exists)(json, 'hasAttachedGallery') ? undefined : json['hasAttachedGallery'],
        'spouse': !(0, runtime_1.exists)(json, 'spouse') ? undefined : json['spouse'],
        'attachedGalleryUrl': !(0, runtime_1.exists)(json, 'attachedGalleryUrl') ? undefined : json['attachedGalleryUrl'],
    };
}
exports.ContentParamsFromJSONTyped = ContentParamsFromJSONTyped;
function ContentParamsToJSON(value) {
    return ContentParamsToJSONTyped(value, false);
}
exports.ContentParamsToJSON = ContentParamsToJSON;
function ContentParamsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'season': value.season,
        'episode': value.episode,
        'leadDraft': value.leadDraft,
        'titleDraft': value.titleDraft,
        'bodyDraft': value.bodyDraft,
        'position': value.position,
        'eyeColor': value.eyeColor,
        'birthPlace': value.birthPlace,
        'birthDate': value.birthDate,
        'weight': value.weight,
        'height': value.height,
        'favouriteColor': value.favouriteColor,
        'favouriteFood': value.favouriteFood,
        'favouriteFilm': value.favouriteFilm,
        'favouriteBand': value.favouriteBand,
        'zodiac': value.zodiac,
        'additionalJson': (0, _1.AdditionalJsonToJSON)(value.additionalJson),
        'duration': value.duration,
        'anonymQuiz': value.anonymQuiz,
        'qzBadgeMask': value.qzBadgeMask,
        'qzBadgeScoreLimit': value.qzBadgeScoreLimit,
        'qzResubmitMessage': value.qzResubmitMessage,
        'qzSubmitFreqMins': value.qzSubmitFreqMins,
        'qzSubmitInterval': value.qzSubmitInterval,
        'qzSubmitIntervalStart': value.qzSubmitIntervalStart,
        'questions': value.questions === undefined ? undefined : (value.questions.map(_1.QuizQuestionToJSON)),
        'contentListUrl': (0, _1.ContentListUrlToJSON)(value.contentListUrl),
        'embedVideoUrl': value.embedVideoUrl,
        'galleryImages': value.galleryImages === undefined ? undefined : (value.galleryImages.map(_1.GalleryImageToJSON)),
        'showVoteResults': value.showVoteResults,
        'answers': value.answers === undefined ? undefined : (value.answers.map(_1.VoteAnswerToJSON)),
        'totalCount': value.totalCount,
        'contents': value.contents === undefined ? undefined : (value.contents.map(_1.ContentDataToJSON)),
        'payload': (0, _1.ContentListDataPayloadToJSON)(value.payload),
        'tabs': value.tabs === undefined ? undefined : (value.tabs.map(_1.CelebTabsToJSON)),
        'publishedOnSixPlay': value.publishedOnSixPlay,
        'rtlMostEpisodeUrl': value.rtlMostEpisodeUrl,
        'rtlMostEpisodeTitle': value.rtlMostEpisodeTitle,
        'celebContentId': value.celebContentId,
        'fellOut': value.fellOut,
        'episodeDate': value.episodeDate === undefined ? undefined : (value.episodeDate === null ? null : value.episodeDate.toISOString().substr(0, 10)),
        'featuredContents': value.featuredContents === undefined ? undefined : (value.featuredContents.map(_1.MinimalContentDataToJSON)),
        'embeddableFeaturedContent': value.embeddableFeaturedContent,
        'rtlMostEpisode': value.rtlMostEpisode,
        'rtlMostSeason': value.rtlMostSeason,
        'hightlightedTags': value.hightlightedTags === undefined ? undefined : (value.hightlightedTags.map(_1.TagToJSON)),
        'alternativeImageUrls': (0, _1.AlternativeImageUrlsToJSON)(value.alternativeImageUrls),
        'withVideo': value.withVideo,
        'isLiveFeed': value.isLiveFeed,
        'embedGalleryUrl': value.embedGalleryUrl,
        'hasAttachedGallery': value.hasAttachedGallery,
        'spouse': value.spouse,
        'attachedGalleryUrl': value.attachedGalleryUrl,
    };
}
exports.ContentParamsToJSONTyped = ContentParamsToJSONTyped;
