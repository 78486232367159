"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuizDataToJSONTyped = exports.QuizDataToJSON = exports.QuizDataFromJSONTyped = exports.QuizDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuizDataFromJSON(json) {
    return QuizDataFromJSONTyped(json, false);
}
exports.QuizDataFromJSON = QuizDataFromJSON;
function QuizDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
        'score': !(0, runtime_1.exists)(json, 'score') ? undefined : json['score'],
    };
}
exports.QuizDataFromJSONTyped = QuizDataFromJSONTyped;
function QuizDataToJSON(value) {
    return QuizDataToJSONTyped(value, false);
}
exports.QuizDataToJSON = QuizDataToJSON;
function QuizDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'score': value.score,
    };
}
exports.QuizDataToJSONTyped = QuizDataToJSONTyped;
