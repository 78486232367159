"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqSubtopicQuestionToJSONTyped = exports.FaqSubtopicQuestionToJSON = exports.FaqSubtopicQuestionFromJSONTyped = exports.FaqSubtopicQuestionFromJSON = void 0;
const _1 = require("./");
function FaqSubtopicQuestionFromJSON(json) {
    return FaqSubtopicQuestionFromJSONTyped(json, false);
}
exports.FaqSubtopicQuestionFromJSON = FaqSubtopicQuestionFromJSON;
function FaqSubtopicQuestionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'question': json['question'],
        'tag': json['tag'],
        'answer': (0, _1.FaqSubtopicQuestionAnswerFromJSON)(json['answer']),
    };
}
exports.FaqSubtopicQuestionFromJSONTyped = FaqSubtopicQuestionFromJSONTyped;
function FaqSubtopicQuestionToJSON(value) {
    return FaqSubtopicQuestionToJSONTyped(value, false);
}
exports.FaqSubtopicQuestionToJSON = FaqSubtopicQuestionToJSON;
function FaqSubtopicQuestionToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'question': value.question,
        'tag': value.tag,
        'answer': (0, _1.FaqSubtopicQuestionAnswerToJSON)(value.answer),
    };
}
exports.FaqSubtopicQuestionToJSONTyped = FaqSubtopicQuestionToJSONTyped;
