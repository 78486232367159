"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuizAnswerToJSONTyped = exports.QuizAnswerToJSON = exports.QuizAnswerFromJSONTyped = exports.QuizAnswerFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuizAnswerFromJSON(json) {
    return QuizAnswerFromJSONTyped(json, false);
}
exports.QuizAnswerFromJSON = QuizAnswerFromJSON;
function QuizAnswerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'quizQuestionId': !(0, runtime_1.exists)(json, 'quizQuestionId') ? undefined : json['quizQuestionId'],
        'answer': !(0, runtime_1.exists)(json, 'answer') ? undefined : json['answer'],
        'answerDetail': !(0, runtime_1.exists)(json, 'answerDetail') ? undefined : json['answerDetail'],
        'sequenceNr': !(0, runtime_1.exists)(json, 'sequenceNr') ? undefined : json['sequenceNr'],
        'imageId': !(0, runtime_1.exists)(json, 'imageId') ? undefined : json['imageId'],
        'defaultValue': !(0, runtime_1.exists)(json, 'defaultValue') ? undefined : json['defaultValue'],
        'correct': !(0, runtime_1.exists)(json, 'correct') ? undefined : json['correct'],
        'score': !(0, runtime_1.exists)(json, 'score') ? undefined : json['score'],
        'archive': !(0, runtime_1.exists)(json, 'archive') ? undefined : json['archive'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
    };
}
exports.QuizAnswerFromJSONTyped = QuizAnswerFromJSONTyped;
function QuizAnswerToJSON(value) {
    return QuizAnswerToJSONTyped(value, false);
}
exports.QuizAnswerToJSON = QuizAnswerToJSON;
function QuizAnswerToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'quizQuestionId': value.quizQuestionId,
        'answer': value.answer,
        'answerDetail': value.answerDetail,
        'sequenceNr': value.sequenceNr,
        'imageId': value.imageId,
        'defaultValue': value.defaultValue,
        'correct': value.correct,
        'score': value.score,
        'archive': value.archive,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
    };
}
exports.QuizAnswerToJSONTyped = QuizAnswerToJSONTyped;
