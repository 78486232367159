"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2025.01.21.)
 * RTL 2021 API (2025.01.21.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteTypesToJSONTyped = exports.SiteTypesToJSON = exports.SiteTypesFromJSONTyped = exports.SiteTypesFromJSON = exports.SiteTypes = void 0;
/**
 * Type of SiteTypes as types of site
 * @export
 * @enum {string}
 */
var SiteTypes;
(function (SiteTypes) {
    SiteTypes["Show"] = "show";
    SiteTypes["Channel"] = "channel";
})(SiteTypes = exports.SiteTypes || (exports.SiteTypes = {}));
function SiteTypesFromJSON(json) {
    return SiteTypesFromJSONTyped(json, false);
}
exports.SiteTypesFromJSON = SiteTypesFromJSON;
function SiteTypesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SiteTypesFromJSONTyped = SiteTypesFromJSONTyped;
function SiteTypesToJSON(value) {
    return SiteTypesToJSONTyped(value, false);
}
exports.SiteTypesToJSON = SiteTypesToJSON;
function SiteTypesToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.SiteTypesToJSONTyped = SiteTypesToJSONTyped;
